import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { StoresIds, Region } from '~/types'
import { useHttpClient } from '~/composables/useHttpClient'
import globalFlag from '@/assets/icons/countries/global.png'
import countryList from '@/assets/all.json'

export const useHeaderMainStore = defineStore(
    StoresIds.HEADER_STORE,
    () => {
        const isHeaderFixed = ref(false)
        const isShowShareButton = ref(false)
        const countries = ref<Region[]>([])
        const menuList = ref()
        const selectedRegion = ref<Region>()
        const industries = ref()
        const specializations = ref()
        const stories = ref([])

        const route = useRoute()
        const slug = computed(
            () =>
                route.query.region ||
                selectedRegion.value?.slug ||
                'global'
        )
        const getCountryIconUrl = (countryCode: string) => {
            const cc = countryList
                .find((c: any) => c.countryCode === countryCode)
                ?.countryCode?.toLowerCase()
            return `https://storage.googleapis.com/gratanet/web_files/flag/${cc}.webp`
        }

        const getHeaderData = async (isMainPage = true) => {
            if (!slug.value) {
                console.error('SLUG IS ', slug.value)
            }

            try {
                await useHttpClient('/content/header', {
                    query: {
                        slug: slug.value,
                        main: isMainPage
                    }
                }).then(response => {
                    const rawCountries =
                        response?.data?.value?.countries

                    if (response.data?.value?.stories) {
                        stories.value = response.data.value.stories
                    }
                    if (!countries.value?.length) {
                        countries.value = rawCountries?.map(
                            country => {
                                const item = {
                                    id: country.id,
                                    slug: country.slug,
                                    icon:
                                        country.slug === 'global'
                                            ? globalFlag
                                            : getCountryIconUrl(
                                                  country.countryCode
                                              ),
                                    countryLanguages:
                                        country.translations,
                                    name: country.countryName,
                                    translations:
                                        country.translations,
                                    countryCode: country.countryCode
                                }

                                return item
                            }
                        )
                    }

                    menuList.value =
                        response?.data?.value?.menus?.sort(
                            (a, b) => a.order - b.order
                        )

                    if (countries.value?.length) {
                        if (!selectedRegion.value?.slug) {
                            selectedRegion.value =
                                countries.value?.find(
                                    c => c.slug === slug.value
                                )
                        }

                        countries.value = countries.value
                            .map(item => ({
                                ...item,
                                selected:
                                    selectedRegion.value?.slug ===
                                    item.slug
                            }))
                            .sort((a, b) => {
                                if (a.slug === 'global') return -1
                                if (b.slug === 'global') return 1

                                // Then prioritize selected items
                                if (a.selected && !b.selected)
                                    return -1
                                if (!a.selected && b.selected)
                                    return 1

                                // Finally, sort by name
                                const nameA = a.name.toUpperCase()
                                const nameB = b.name.toUpperCase()

                                if (nameA < nameB) {
                                    return -1
                                }
                                if (nameA > nameB) {
                                    return 1
                                }
                                return 0
                            })
                    }
                })
            } catch (e) {
                console.error('ERROR WHEN GET MAIN PAGE DATA', e)
            }
        }

        return {
            countries,
            menuList,
            selectedRegion,
            industries,
            specializations,
            getHeaderData,
            isHeaderFixed,
            isShowShareButton,
            stories
        }
    }
)
